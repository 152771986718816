import docReady from '../../common/docReady';
import code from '../../common/lib/code';

const forecast = (): void => {
  const userLocale: string = navigator.language || 'en-US';

  const options: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    month: 'short',
    day: 'numeric'
  };

  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  };

  const date = new Date().toLocaleDateString(userLocale, options);
  const time = new Date().toLocaleTimeString(userLocale, timeOptions);

  const timeElement = document.getElementById('forecast-time');
  const dateElement = document.getElementById('forecast-date');

  if (timeElement) {
    timeElement.textContent = `Last Updated: ${time}`;
  }

  if (dateElement) {
    dateElement.textContent = date;
  }
};

docReady(() => {
  code();
  forecast();
});
